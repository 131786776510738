import { Service4DeliveryEndpoints } from "..";
import { CheckoutMethod } from "../../../constants";
import { PrintToCashSystemResponseData, PrintToCashSystemResponseDataToBack } from "../../../services/ServiceRtCashSystem/CashSystemProtocol";
import { Cart } from "../../../types/Cart";
import { Order } from "../../../types/Order";
import { Payload } from "../../../utils/httpClient/AxiosWrapper";
import { OrderRequestManager } from "../../../utils/OrderRequestManager";
import { callPayment } from "./Payment";

export interface FiscalDocument {
	kiosk_cart_id: string;
	receipt: string;
	serial_number?: string;
}

export interface OrderNoPaymentResponse {
	status: string;
	order_id: string;
	kiosk_cart_id: number;
}
export interface OrderDocumentResponse {
	status: "success" | "error";
	last_error: string;
}

export const orderNoPayment = async (isFiscalMode: boolean, cart: Cart, order: Order): Promise<OrderNoPaymentResponse> => {
	const orderRequestManager = new OrderRequestManager(order);

	const payload: Payload = {
		uid: orderRequestManager.getNextOrderRequestId(),
		cart: JSON.stringify(cart),
		is_fiscal_mode: Number(isFiscalMode ?? "0"),
		checkout_method: order.checkoutMethod.toString()
	};
	if (order.checkoutMethod === CheckoutMethod.PLACE_NUMBER) payload.place_number = order.placeNumber;

	return callPayment(payload, Service4DeliveryEndpoints.orderNoPayment);
};

export const orderDocument = async (fiscalDocument: FiscalDocument): Promise<OrderDocumentResponse> => {
	return callPayment(fiscalDocument as Payload, Service4DeliveryEndpoints.orderDocument);
};

export const serializeOrderDocument = (cartId: number, receipt: PrintToCashSystemResponseData, terminalSerialNumber?: string): FiscalDocument => {
	const newReceipt: PrintToCashSystemResponseDataToBack = { ...receipt.status };
	if (receipt.ultimoDocDigitale) {
		delete receipt.ultimoDocDigitale.pdfBase64;
		newReceipt.ultimoDocDigitale = receipt.ultimoDocDigitale;
	}

	const fiscalDocument: FiscalDocument = {
		kiosk_cart_id: cartId.toString(),
		receipt: JSON.stringify(newReceipt)
	};
	if (terminalSerialNumber) fiscalDocument.serial_number = terminalSerialNumber;

	return fiscalDocument;
};
