import { useState } from "react";
import { Language } from "../constants";
import i18n from "../utils/i18n/i18n";

interface useLanguageResponse {
	language: Language;
	setLanguage: (language: Language) => void;
}

export const useLanguage = (): useLanguageResponse => {
	const [currentLanguage, setCurrentLanguage] = useState<Language>((localStorage.getItem("language") ?? Language.english) as Language);

	const setLanguage = (language: Language) => {
		localStorage.setItem("language", language);
		i18n.changeLanguage(language);
		setCurrentLanguage(language);
	};

	return { language: currentLanguage, setLanguage };
};
