import { CheckCircleOutlineRounded, HighlightOffRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import TouchButton from "../../Layout/Buttons/TouchButton";
import PinKeyboard from "../../Layout/Keyboard/PinKeyboard";
import ConfirmModal from "./Wrappers/ConfirmModal";

interface PinPickerModalProps {
	closeModal: () => void;
	confirmPin: (pin: string) => void;
}

const PinPickerModal = ({ closeModal, confirmPin }: PinPickerModalProps) => {
	const { t } = useTranslation();

	const [currentPin, setCurrentPin] = useState<string>("");

	const handleConfirmPin = useCallback(() => confirmPin(currentPin), [currentPin, confirmPin]);

	return (
		<ConfirmModal>
			<Box>
				<Typography color="primary" variant="h4" textAlign="center">
					{t("system.admin.access.pin")}
				</Typography>
				<PinKeyboard digitText={currentPin} setDigitText={setCurrentPin} isPasswordMode={true} />
			</Box>
			<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
				<TouchButton color="error" variant="contained" size="large" startIcon={<HighlightOffRounded />} onClick={closeModal}>
					<Typography variant="button">{t("checkout.eatInPlacePicker.cancel")}</Typography>
				</TouchButton>
				<TouchButton
					color="success"
					variant="contained"
					size="large"
					endIcon={<CheckCircleOutlineRounded />}
					disabled={currentPin === ""}
					onClick={handleConfirmPin}
				>
					<Typography variant="button">{t("checkout.eatInPlacePicker.confirm")}</Typography>
				</TouchButton>
			</Box>
		</ConfirmModal>
	);
};

export default memo(PinPickerModal);
