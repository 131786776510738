import { useState } from "react";
import { MenuLoad } from "./Menu";

export interface useMenuResponse {
	downloadMenu: (isForced?: boolean) => Promise<void>;
	setMenuNotified: (value: boolean) => void;
	menuLoading: boolean;
	menuError: boolean;
	menuNotified: boolean;
}

export const useMenu = (): useMenuResponse => {
	const [menuLoading, setMenuLoading] = useState<boolean>(false);
	const [menuError, setMenuError] = useState<boolean>(false);
	const [menuNotified, setMenuNotified] = useState<boolean>(true);

	const downloadMenu = async (isForced: boolean = false) => {
		if (isForced === true) {
			localStorage.setItem("menuHash", "forced");
		}

		setMenuLoading(true);

		await MenuLoad()
			.then((result: boolean) => {
				setMenuError(!result);
			})
			.catch(() => {
				setMenuError(true);
			})
			.finally(() => {
				setMenuNotified(false);
				setMenuLoading(false);
			});
	};

	return { downloadMenu, setMenuNotified, menuLoading, menuError, menuNotified };
};
