import { Service4DeliveryEndpoints } from "..";
import { callAxios, callAxiosResponseInterface } from "../../../utils/httpClient/AxiosWrapper";

export interface callLoginResponse {
	status: any;
	data: {
		success: boolean;
	};
}

export const callLogin = (): Promise<callLoginResponse | callAxiosResponseInterface> => {
	return new Promise(async (resolve) => {
		callAxios({
			method: "post",
			url: Service4DeliveryEndpoints.authLogin,
			body: JSON.stringify({}),
			headers: JSON.stringify({ accept: "*/*" })
		})
			.then((response: any) => {
				resolve(response as callLoginResponse);
			})
			.catch((err) => {
				if (err.data.error_code.startsWith("error.")) {
					resolve({ ...err, data: { error_code: err.data.error_code.replace("error.", "") } });
				} else {
					resolve(err);
				}
			});
	});
};
