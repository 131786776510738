export enum GoodType {
	goods = "goods",
	services = "services"
}

export enum ItemInfoTypology {
	plu = "plu",
	cond_add = "cond_add",
	cond_sub = "cond_sub",
	cook = "cook"
}

export enum ItemLinkedListMode { // 'add' to add, 'sub' to remove
	add = "add",
	sub = "sub"
}
export enum ItemLinkedListItemMode {
	subMultiple = "sub-multiple", // multiple choice
	subSingle = "sub-single" // single choice
}
