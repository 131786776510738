import { cloneDeep } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FiscalDocument, orderDocument, OrderDocumentResponse } from "../services/Service4Delivery/OrderPayments/Order";
import { PaymentErrorResponse } from "../services/Service4Delivery/OrderPayments/Payment";
import Logger from "../utils/Logger";

interface DocumentStack {
	stack: FiscalDocument[];
}
/**
 * Handles the Document Stack
 *
 * It leverages the local storage to store undelivered documents.
 * By calling the "process" function you can try to re-send afterward
 * the document not sent during the usual app flow.
 *
 * @returns
 */
const useOrderDocumentStack = () => {
	const defaultStack = useMemo(() => {
		return { stack: [] };
	}, []);

	const [documentStack, setDocumentStack] = useState<DocumentStack>(defaultStack);

	/**
	 * Retrieve the document stack from the local storage
	 */ const retrieveFromStorage = useCallback((): DocumentStack => {
		return JSON.parse(localStorage.getItem("documentStack") ?? JSON.stringify(defaultStack));
	}, [defaultStack]);
	/**
	 * Put the document stack into the local storage
	 */
	const putIntoStorage = useCallback((documentStack: DocumentStack): void => {
		return localStorage.setItem("documentStack", JSON.stringify(documentStack));
	}, []);

	/**
	 * Get the last document in the stack
	 */
	const getLast = useCallback((): FiscalDocument | null => {
		const localStack = cloneDeep(documentStack);
		if (localStack.stack.length === 0) return null;

		const lastDocument: FiscalDocument = localStack.stack[localStack.stack.length - 1];
		return lastDocument;
	}, [documentStack]);

	/**
	 * Add a document object into the stack.
	 */
	const addDocumentToStack = useCallback(
		(fiscalDocument: FiscalDocument): void => {
			const localStack = cloneDeep(documentStack);
			localStack.stack.push(fiscalDocument);
			setDocumentStack(localStack);
			putIntoStorage(localStack);
		},
		[documentStack, putIntoStorage]
	);

	/**
	 * Remove last document from the stack
	 */
	const removeLastDocumentFromStack = useCallback((): void => {
		const localStack = cloneDeep(documentStack);
		if (localStack.stack.length === 0) return;

		localStack.stack.shift();
		setDocumentStack({ stack: localStack.stack });
		putIntoStorage({ stack: localStack.stack });
	}, [documentStack, putIntoStorage]);

	useEffect(() => {
		// send last document in stack. In case of success remove the document from the stack and update state and localStorage
		const sendLastDocument = async () => {
			const lastDocument = getLast();
			if (!lastDocument) return;

			await orderDocument(lastDocument)
				.then((response: OrderDocumentResponse) => {
					if (response.status === "success") {
						Logger.log("Document sent afterwards!");
						removeLastDocumentFromStack();
					} else if (response.status === "error") {
						Logger.error(response.last_error);
					}
				})
				.catch((err: PaymentErrorResponse) => {
					Logger.error(err);
				});
		};

		if (documentStack.stack.length === 0) return;

		sendLastDocument();
	}, [documentStack, getLast, removeLastDocumentFromStack]);

	/**
	 * it process the stack currently stored in local storage
	 */
	const process = useCallback((): void => {
		// if non empty stack, process it
		const localStack: DocumentStack = retrieveFromStorage();
		if (localStack.stack.length === 0) {
			return;
		}
		setDocumentStack(localStack);
	}, [retrieveFromStorage]);

	return {
		processDocumentStack: process,
		addDocumentToStack
	};
};
export default useOrderDocumentStack;
