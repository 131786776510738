export enum ModalViewName {
	productDetail = "productDetail",
	orderReview = "orderReview",
	filteredOrderReview = "filteredOrderReview",
	placePicker = "placePicker",
	payment = "payment",
	paymentMethod = "paymentMethod",
	receipt = "receipt",
	pinPicker = "pinPicker"
}
