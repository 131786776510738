import axios, { AxiosError, AxiosResponse } from "axios";

type Method = "head" | "options" | "put" | "post" | "patch" | "delete" | "get";

export interface Payload {
	[key: string]: any;
}

export interface axiosRequestInterface {
	method: Method;
	url: string;
	body: string;
	headers: string;
	cashsystem?: boolean;
	retry?: number;
}

export interface callAxiosResponseInterface {
	status: number;
	data: any;
}

const wait = (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

export const callAxios = (
	request: axiosRequestInterface,
	retryNumber: number = 0,
	delay: number = 500,
	timeout: number = 20000
): Promise<callAxiosResponseInterface> => {
	return new Promise(async (resolve, reject) => {
		const output: callAxiosResponseInterface = { status: 0, data: null };

		const configs = request.cashsystem
			? { headers: request.headers, timeout: timeout }
			: {
					headers: {
						"Content-Type": "application/json",
						kioskauth:
							(localStorage.getItem("Username") ?? "") +
							";" +
							(localStorage.getItem("Password") ?? "") +
							";" +
							(localStorage.getItem("kioskId") ?? ""),
						...JSON.parse(request.headers)
					},
					timeout: timeout
			  };
		const body = request.cashsystem ? request.body : JSON.parse(request.body);

		const axiosRequest: any[] = request.method === "get" ? [configs] : [body, configs];
		axios[request.method](request.url, ...axiosRequest)
			.then((resp: AxiosResponse) => {
				output.status = resp.status;
				output.data = resp.data;
				resolve(output);
			})
			.catch((err: AxiosError) => {
				if (err.response) {
					if (retryNumber > 0 && err.response?.status === 400) {
						return wait(delay).then(() => {
							callAxios(request, retryNumber--, delay);
						});
					}
					// The request was made and the server responded with a status code that falls out of the range of 2xx
					output.status = err.response?.status;
					output.data = err.response?.data ?? { error_code: err.code };
				} else {
					// if (err.request) The request was made but no response was received
					// otherwise Something happened in setting up the request that triggered an Error
					output.status = 503;
					output.data = { error_code: err.code };
				}
				reject(output);
			});
	});
};
