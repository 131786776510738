import { Service4DeliveryEndpoints } from "..";
import { LoggerChannel } from "../../../constants";
import { defaultRestaurantInfo } from "../../../constants/defaults";
import { RestaurantInfo } from "../../../types/RestaurantInfo";
import { AppConfig } from "../../../types/Theme";
import { callAxios, callAxiosResponseInterface, Payload } from "../../../utils/httpClient/AxiosWrapper";
import Logger from "../../../utils/Logger";
import { RestaurantInfoVersionMapper } from "../../../utils/RestaurantInfoVersionMapper";

export interface callRestaurantInfoResponse {
	status: number;
	data: RestaurantInfo;
}

export const callRestaurantInfo = async (): Promise<RestaurantInfo> => {
	return new Promise(async (resolve) => {
		callAxios({
			method: "post",
			url: Service4DeliveryEndpoints.configGet,
			body: JSON.stringify({}),
			headers: JSON.stringify({ accept: "*/*" })
		})
			.then((response: callAxiosResponseInterface) => {
				const goodResponse = response as callRestaurantInfoResponse;

				// adapt the new restaurantInfo to the current version of the software
				resolve(new RestaurantInfoVersionMapper(goodResponse.data).map());
			})
			.catch((err) => {
				Logger.error(["Retrieve restaurant info error: ", err], LoggerChannel.api);

				// try to load restaurantInfo from storage;
				const storedRestaurantInfo: string | null = localStorage.getItem("restaurant_info");
				resolve(storedRestaurantInfo ? (JSON.parse(storedRestaurantInfo) as RestaurantInfo) : defaultRestaurantInfo);
			});
	});
};

export const putRestaurantInfoConfig = async (currentConfig: AppConfig, hideSnoozed?: number): Promise<boolean> => {
	const payload: Payload = {
		config: JSON.stringify(currentConfig)
	};
	if (hideSnoozed !== undefined) payload.hide_snoozed = hideSnoozed;

	return new Promise(async (resolve) => {
		callAxios({
			method: "post",
			url: Service4DeliveryEndpoints.configSet,
			body: JSON.stringify(payload),
			headers: JSON.stringify({ accept: "*/*" })
		})
			.then((response: callAxiosResponseInterface) => {
				resolve(true);
			})
			.catch((err) => {
				Logger.error(["Upload restaurant info error: ", err], LoggerChannel.api);
				resolve(false);
			});
	});
};
