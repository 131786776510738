import { buildTheme } from "../../../components/Theme/ThemeConfig";
import { calculateKioskIdSetting, useTheme } from "../../../components/Theme/ThemeWrapper";
import { LoggerChannel } from "../../../constants";
import { defaultRestaurantInfo } from "../../../constants/defaults";
import { RestaurantInfo } from "../../../types/RestaurantInfo";
import { loadMediaAssets } from "../../../utils/cdnAssets";
import logger from "../../../utils/Logger";
import { useMenu } from "../Menu/useMenu";
import { callRestaurantInfo, putRestaurantInfoConfig } from "./RestaurantInfo";

export const useRestaurantInfo = () => {
	const { settings, setContextTheme, setContextSetting, setContextRestaurantInfo, setContextHideSnoozed } = useTheme();
	const { downloadMenu, menuError } = useMenu();

	const downloadConfigAndMenu = async () => {
		const restaurantInfo: RestaurantInfo = await callRestaurantInfo();
		const restaurantId = localStorage.getItem("Username") ?? "";

		localStorage.setItem("restaurant_info", JSON.stringify(restaurantInfo));
		localStorage.setItem("localConfig", JSON.stringify(restaurantInfo.config ?? defaultRestaurantInfo.config));
		localStorage.setItem("localHideSnoozed", JSON.stringify(restaurantInfo.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed));
		if (setContextTheme && setContextSetting && setContextRestaurantInfo && setContextHideSnoozed) {
			setContextRestaurantInfo(restaurantInfo);
			setContextTheme(buildTheme(restaurantInfo.config));
			setContextSetting(calculateKioskIdSetting(restaurantInfo.config.settings, settings.kioskId));
			setContextHideSnoozed(restaurantInfo.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed);
		}

		await loadMediaAssets(restaurantInfo.media, restaurantInfo.media_baseurl, restaurantId).catch((reason: any) => {
			logger.log(reason, LoggerChannel.api);
		});

		const forceMenuDownload: boolean = restaurantInfo.config.versionNumber !== defaultRestaurantInfo.config.versionNumber;
		if (forceMenuDownload) {
			restaurantInfo.config.versionNumber = defaultRestaurantInfo.config.versionNumber;
			await putRestaurantInfoConfig(restaurantInfo.config);
		}

		await downloadMenu(forceMenuDownload);
		if (menuError) {
			logger.error("Error retrieving menu", LoggerChannel.api);
		}
	};

	return { downloadConfigAndMenu };
};
