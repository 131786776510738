import { Settings } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { memo, MouseEvent as ReactMouseEvent, useState } from "react";
import { useTheme } from "../../Theme/ThemeWrapper";
import TouchIcon from "./TouchIcon";

interface IHiddenAdminButtonProps {
	handleAction: () => void;
}

const HiddenAdminButton = ({ handleAction }: IHiddenAdminButtonProps) => {
	const { theme } = useTheme();

	const [configEnabled, setConfigEnabled] = useState<number>(0);

	const showAndHandleClick = (e: ReactMouseEvent<HTMLElement>) => {
		setConfigEnabled((prev) => prev + 1);
		if (configEnabled > 1) {
			setConfigEnabled(0);
			handleAction();
		}
		e.stopPropagation();
	};

	return (
		<Stack
			className="hiddenConfigIcon"
			direction="row-reverse"
			spacing={1}
			sx={{
				position: "absolute",
				top: 0,
				right: 0,
				zIndex: "1000"
			}}
		>
			<TouchIcon sx={{ color: configEnabled > 1 ? theme.systemTheme.palette.info.main : "transparent" }} size="large" onClick={showAndHandleClick}>
				<Settings sx={{ fontSize: "6rem" }} />
			</TouchIcon>
		</Stack>
	);
};

export default memo(HiddenAdminButton);
