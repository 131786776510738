import { ThemeProvider } from "@mui/material";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { defaultConfig, defaultRestaurantInfo } from "../../constants/defaults";
import { RestaurantInfo } from "../../types/RestaurantInfo";
import { AppConfig, AppTheme, SettingsConfig } from "../../types/Theme";
import { buildTheme } from "./ThemeConfig";

interface ThemeContextProps {
	theme: AppTheme;
	setContextTheme: Dispatch<SetStateAction<AppTheme>> | null;
	settings: SettingsConfig;
	setContextSetting: Dispatch<SetStateAction<SettingsConfig>> | null;
	restaurantInfo: RestaurantInfo;
	setContextRestaurantInfo: Dispatch<SetStateAction<RestaurantInfo>> | null;
	hideSnoozed: number;
	setContextHideSnoozed: Dispatch<SetStateAction<number>> | null;
	restaurantId: string;
	setContextRestaurantId: Dispatch<SetStateAction<string>> | null;
}

// Retrieve config from backend
const restaurantInfo = (): RestaurantInfo => {
	const info: RestaurantInfo = JSON.parse(localStorage.getItem("restaurant_info") ?? JSON.stringify(defaultRestaurantInfo));

	return {
		country: info.country === "" ? defaultRestaurantInfo.country : info.country,
		currency: info.currency === "" ? defaultRestaurantInfo.currency : info.currency,
		config: info.config ?? defaultRestaurantInfo.config,
		hide_snoozed: info.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed,
		media: info.media ?? defaultRestaurantInfo.media,
		media_baseurl: info.media_baseurl ?? defaultRestaurantInfo.media_baseurl
	};
};

// The config could be overridden by a localStorage config
const localStorageConfig = localStorage.getItem("localConfig");
const localHideSnoozed = localStorage.getItem("localHideSnoozed");
const localRestaurantId = localStorage.getItem("Username") ?? "";

const backRestaurantInfo: RestaurantInfo = restaurantInfo();
const chosenAppConfig: AppConfig = localStorageConfig ? JSON.parse(localStorageConfig) : backRestaurantInfo.config;
const chosenHideSnoozed: number = localHideSnoozed ? parseInt(localHideSnoozed) : backRestaurantInfo.hide_snoozed;

export const calculateKioskIdSetting = (settingsConfigList: SettingsConfig[], kioskId?: number): SettingsConfig => {
	const initialKioskId: number = kioskId === undefined ? 0 : kioskId;
	let localKioskId: number = initialKioskId ? initialKioskId : parseInt(localStorage.getItem("kioskId") ?? "0");
	let chosenSettings: SettingsConfig = settingsConfigList[0];

	// Starting with version 1.4.3 the KioskIds range is [101 - 199]. Previously it was [1 - 99].
	// Older Settings objects must be adapted accordingly (+100)
	if (localKioskId > 0 && localKioskId < 101) {
		localKioskId = localKioskId + 100;
	}

	// if in config there are only 2 settings (i.e. the default settings and just one settings configured)
	// and there is no kioskId in local storage
	if (settingsConfigList.length === 2 && localKioskId === 0) {
		chosenSettings = settingsConfigList[1]; //... then choose the only one configured
	} else {
		// otherwise get the configuration related to the localKioskId in storage
		const localConfigKioskId = settingsConfigList.find((sc: SettingsConfig) => sc.kioskId === localKioskId);
		if (localConfigKioskId) {
			chosenSettings = localConfigKioskId;
		}
	}

	// save localKioskId in storage
	localStorage.setItem("kioskId", String(chosenSettings.kioskId));
	return chosenSettings;
};

// Build the MUI Theme using the chosen Configurations
const defaultTheme: AppTheme = buildTheme(chosenAppConfig);

export const ThemeContext = createContext<ThemeContextProps>({
	theme: defaultTheme,
	setContextTheme: null,
	settings: defaultConfig.settings,
	setContextSetting: null,
	restaurantInfo: defaultRestaurantInfo,
	setContextRestaurantInfo: null,
	hideSnoozed: defaultRestaurantInfo.hide_snoozed,
	setContextHideSnoozed: null,
	restaurantId: localRestaurantId,
	setContextRestaurantId: null
});

type Props = {
	children?: ReactNode;
};

export const ThemeWrapper = ({ children }: Props) => {
	const [currentTheme, setContextTheme] = useState<AppTheme>(defaultTheme);
	const [currentSetting, setContextSetting] = useState<SettingsConfig>(defaultConfig.settings);
	const [currentRestaurantInfo, setContextRestaurantInfo] = useState<RestaurantInfo>(restaurantInfo());
	const [currentHideSnoozed, setContextHideSnoozed] = useState<number>(chosenHideSnoozed);
	const [currentRestaurantId, setContextRestaurantId] = useState<string>(localRestaurantId);

	return (
		<ThemeContext.Provider
			value={{
				theme: currentTheme,
				setContextTheme,
				settings: currentSetting,
				setContextSetting,
				restaurantInfo: currentRestaurantInfo,
				setContextRestaurantInfo,
				hideSnoozed: currentHideSnoozed,
				setContextHideSnoozed,
				restaurantId: currentRestaurantId,
				setContextRestaurantId
			}}
		>
			<ThemeProvider theme={currentTheme.customTheme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export const useTheme = () => useContext(ThemeContext);
