import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useFonts } from "../hooks/useFonts";
import Choose from "./Checkout/Choose";
import Home from "./Home/Home";
import Login from "./Login/Login";
import "./Main.scss";

/** Main wrapper for the whole app. */
function Main(): JSX.Element {
	useFonts();

	const Admin = lazy(() => import("./Admin/Admin"));
	const OrderMaker = lazy(() => import("./Checkout/OrderMaker"));

	return (
		<div className="Main">
			<Suspense fallback={<div>Loading…</div>}>
				<BrowserRouter>
					<Routes>
						<Route path="/Admin/:role" element={<Admin />} />

						<Route path="/" element={<Home />} />
						<Route path="/Login" element={<Login />} />
						<Route path="/Checkout/Choose" element={<Choose />} />

						<Route path="/Checkout/:method" element={<OrderMaker />} />

						<Route path="/Reload" />
					</Routes>
				</BrowserRouter>
			</Suspense>
		</div>
	);
}

export default Main;
