import { Box, Container, Dialog, Typography, alpha } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { AdminRole, CheckoutMethod, ModalViewName } from "../../constants";
import { defaultAppConfig } from "../../constants/defaults";
import { useModal } from "../../hooks/useModal";
import useStaticImage from "../../hooks/useStaticImage";
import { useGetRestaurantMedia } from "../../utils/cdnAssets";
import LanguageFlagStrip from "../LanguageFlags/LanguageFlagStrip";
import HiddenAdminButton from "../Layout/Buttons/HiddenAdminButton";
import TouchArea from "../Layout/Buttons/TouchArea";
import { useTheme } from "../Theme/ThemeWrapper";
import "./Choose.scss";
import PinPickerModal from "./Modals/PinPickerModal";

const Choose = () => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { modal, openModal, closeModal } = useModal();

	const { url: bgUrl, isFallback: isBgFallback } = useGetRestaurantMedia("graphic");
	const { url: logoUrl, isFallback: isLogoFallback } = useGetRestaurantMedia("logo");
	const { image: logoLabware } = useStaticImage("system/logoLabware.svg");

	const fgColor: string = theme.customTheme.palette.primary.main;
	const lightColor: string = theme.customTheme.palette.primary.light;
	const darkColor: string = theme.customTheme.palette.primary.dark;
	const bcColor: string = theme.customTheme.palette.background.paper;

	useIdleTimer({
		name: "5minutes-inactive-timer-choose-page",
		onIdle: () => {
			navigate("/");
		},
		timeout: 1000 * 60 * 5
	});

	const handleConfirmPin = useCallback(
		(pin: string): void => {
			if (pin === (theme.config.pin ?? defaultAppConfig.pin)) {
				navigate(`/Admin/${AdminRole.administrator}`);
			} else if (pin === (theme.config.pinOperator ?? defaultAppConfig.pinOperator)) {
				navigate(`/Admin/${AdminRole.operator}`);
			} else {
				closeModal();
			}
		},
		[navigate, closeModal, theme.config.pin, theme.config.pinOperator]
	);

	const handleOpenModal = useCallback(() => openModal(ModalViewName.pinPicker), [openModal]);
	const handleNavigatePlaceNumber = useCallback(() => navigate(`/Checkout/${CheckoutMethod.PLACE_NUMBER}`), [navigate]);
	const handleNavigateTakeaway = useCallback(() => navigate(`/Checkout/${CheckoutMethod.TAKEAWAY}`), [navigate]);

	return (
		<Container className="checkout-choose-container" sx={{ background: `conic-gradient(from 90deg, ${lightColor}, ${darkColor}, ${lightColor})` }}>
			<HiddenAdminButton handleAction={handleOpenModal} />

			<Box className="underBg btnGraphic eatIn" sx={{ backgroundColor: bcColor }}></Box>
			<Box className="underBg btnGraphic takeAway" sx={{ backgroundColor: bcColor }}></Box>

			<Box className="bgLayer" sx={{ background: `url(${bgUrl})` }} />

			{isBgFallback ? (
				<Box
					className="icon-image"
					sx={{
						backgroundColor: alpha(bcColor, 0.9),
						mask: `url(${isLogoFallback ? logoLabware : logoUrl}) no-repeat center / contain`
					}}
				/>
			) : null}

			<TouchArea className="btnGraphic eatIn" onClick={handleNavigatePlaceNumber}>
				<Box className={"cta"} sx={{ color: fgColor }}>
					<Typography variant="h3">{t("checkout.choose.place_number")}</Typography>
				</Box>
			</TouchArea>
			<TouchArea className="btnGraphic takeAway" onClick={handleNavigateTakeaway}>
				<Box className={"cta"} sx={{ color: fgColor }}>
					<Typography variant="h3">{t("checkout.choose.takeaway")}</Typography>
				</Box>
			</TouchArea>

			<Dialog open={modal.open} fullScreen={!modal.overlay} fullWidth={true} maxWidth="xl" onClose={closeModal} scroll="paper">
				{modal.name === ModalViewName.pinPicker && <PinPickerModal closeModal={closeModal} confirmPin={handleConfirmPin} />}
			</Dialog>

			<LanguageFlagStrip />
		</Container>
	);
};

export default Choose;
