import { Backspace } from "@mui/icons-material";
import { Box, Grid, Typography, alpha } from "@mui/material";
import { Dispatch, SetStateAction, memo, useCallback, useMemo } from "react";
import { Font } from "../../../constants";
import { isDarkColor } from "../../../utils/color";
import { useTheme } from "../../Theme/ThemeWrapper";
import TouchButton from "../Buttons/TouchButton";
import TouchIcon from "../Buttons/TouchIcon";

interface IPinKeyboardProps {
	digitText: string;
	setDigitText: Dispatch<SetStateAction<string>>;
	isPasswordMode?: boolean;
	maxLength?: number;
	additional4Characters?: string;
}

const PinKeyboard = ({ digitText, setDigitText, isPasswordMode, maxLength, additional4Characters }: IPinKeyboardProps) => {
	const { theme } = useTheme();

	const greyColor: string = useMemo(
		() => alpha(isDarkColor(theme.systemTheme.palette.background.paper) ? theme.systemTheme.palette.grey[100] : theme.systemTheme.palette.grey[900], 0.9),
		[theme.systemTheme.palette]
	);
	const greyColorMoreContrast: string = useMemo(
		() => alpha(isDarkColor(theme.systemTheme.palette.background.paper) ? theme.systemTheme.palette.grey[200] : theme.systemTheme.palette.grey[800], 0.9),
		[theme.systemTheme.palette]
	);

	const basicKeys: string[] = useMemo(() => ["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0", "backspace"], []);
	const placeNumberMaxLength: number = useMemo(() => maxLength ?? 4, [maxLength]);
	const elementsPerRow: number = useMemo(() => (additional4Characters ? 4 : 3), [additional4Characters]);
	const characters = useMemo(() => digitText.padStart(placeNumberMaxLength, " ").split(""), [digitText, placeNumberMaxLength]);

	const totalKeys: string[] = useMemo(() => {
		let clonedKeys: string[] = basicKeys;
		if (additional4Characters) {
			const newKeys: string[] = additional4Characters ? Array.from(additional4Characters.toUpperCase()) : ["", "", "", ""];
			const chunkSize = 3;
			clonedKeys = [];
			let row = 0;
			for (let i = 0; i < basicKeys.length; i += chunkSize) {
				const chunk = basicKeys.slice(i, i + chunkSize);
				clonedKeys = clonedKeys.concat(newKeys[row] ?? "", chunk);
				row++;
			}
		}
		return clonedKeys;
	}, [basicKeys, additional4Characters]);

	const elementHeight: number = useMemo(() => (100 / totalKeys.length) * elementsPerRow, [totalKeys, elementsPerRow]);

	const addLetterToEnd = useCallback(
		(key: string) => {
			if (digitText.length < placeNumberMaxLength) setDigitText(digitText + key);
		},
		[digitText, placeNumberMaxLength, setDigitText]
	);

	const deleteLastLetter = useCallback(() => {
		if (digitText.length > 0) setDigitText(digitText.substring(0, digitText.length - 1));
	}, [digitText, setDigitText]);

	return (
		<>
			<Box sx={{ display: "flex" }}>
				{characters.map((x, i) => {
					return (
						<Box
							key={"place_char_" + i}
							sx={{
								height: "6rem",
								width: "6rem",
								borderBottom: `0.0625rem solid ${theme.systemTheme.palette.text.primary}`,
								marginLeft: "0.5rem",
								marginRight: "0.5rem"
							}}
						>
							<Typography variant="h2" textAlign="center" fontFamily={Font.openSans}>
								{isPasswordMode ? (x === " " ? " " : "*") : x}
							</Typography>
						</Box>
					);
				})}
			</Box>
			<Box
				sx={{
					width: "100%",
					height: "60%",
					backgroundColor: greyColor,
					borderRadius: "2rem",
					marginTop: "3rem",
					padding: "1rem"
				}}
			>
				<Grid container sx={{ height: "100%" }}>
					{totalKeys.map((key: string, index: number) => {
						const handleAddLetterToEnd = () => addLetterToEnd(key);

						let button = null;

						switch (key) {
							case "":
								button = null;
								break;

							case "backspace":
								button = (
									<TouchIcon
										sx={{ height: "100%", width: "100%", color: theme.systemTheme.palette.background.paper }}
										onClick={deleteLastLetter}
									>
										<Backspace />
									</TouchIcon>
								);
								break;

							default:
								button = (
									<TouchButton
										sx={{ height: "100%", width: "100%", color: theme.systemTheme.palette.background.paper }}
										onClick={handleAddLetterToEnd}
									>
										<Typography variant="h5" fontFamily={Font.openSans}>
											{key}
										</Typography>
									</TouchButton>
								);
								break;
						}

						return (
							<Grid
								item
								key={"key_" + index}
								xs={12 / elementsPerRow}
								sx={{
									height: elementHeight + "%",
									textAlign: "center",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									borderRadius: "2rem",
									backgroundColor: greyColorMoreContrast,
									border: `0.1875rem solid ${greyColor}`
								}}
							>
								{button}
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</>
	);
};

export default memo(PinKeyboard);
