export enum RevenueService {
	none = "NF", // non-fiscal
	IT = "RS-IT" // Italian revenue service
}

/**
 * List of RT device's set-ups using a firmware certified by a local Revenue Service Agency
 */
export const revenueServiceCertifiedTypes = [RevenueService.IT];

/**
 * Check if the given RevenueService is one of the revenue service certified types
 *
 * @param type  // a RevenueService
 * @returns boolean
 */
export function isValidRevenueService(type: RevenueService): boolean {
	return revenueServiceCertifiedTypes.includes(type);
}

export function getRevenueServiceName(value: string): string {
	const indexOfS = Object.values(RevenueService).indexOf(value as unknown as RevenueService);
	return Object.keys(RevenueService)[indexOfS];
}
