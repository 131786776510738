import { LoggerChannel } from "../constants";
import { isObject } from "./functions";

class Logger {
	DEFAULT_PREFIX = "app";
	LOG_VALUE_LENGTH = 100;

	write(level: "log" | "warn" | "error", val: any, prefix: LoggerChannel, verbose: boolean): void {
		if (process.env.NODE_ENV === "production") return;

		const value = val ?? "empty";

		const prefix_text = "[" + prefix.toUpperCase() + "] ";

		if (verbose) {
			console[level](prefix_text, isObject(value) ? { value } : Array.isArray(value) ? [...value] : value);
		} else {
			console[level](
				"%c" + prefix_text + JSON.stringify(value).substring(0, this.LOG_VALUE_LENGTH) + (value.length > this.LOG_VALUE_LENGTH ? "..." : ""),
				`color: ${this.getColor(prefix)}`
			);
		}
	}

	log(value: any, prefix: LoggerChannel = LoggerChannel.app, verbose: boolean = true): void {
		this.write("log", value, prefix, verbose);
	}

	warn(value: any, prefix: LoggerChannel = LoggerChannel.app, verbose: boolean = true): void {
		this.write("error", value, prefix, verbose);
	}

	error(value: any, prefix: LoggerChannel = LoggerChannel.app, verbose: boolean = true): void {
		this.write("error", value, prefix, verbose);
	}

	getColor(channel: LoggerChannel): string {
		switch (channel) {
			case LoggerChannel.app:
				return "lightgreen";
			case LoggerChannel.storage:
				return "lightyellow";
			case LoggerChannel.api:
				return "cyan";
			case LoggerChannel.stripe:
				return "magenta";
			default:
				return "gray";
		}
	}
}

const logger = new Logger();
export default logger;
