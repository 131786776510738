import { Font, Language, RevenueService } from "../constants";
import { PaymentIntentResponse } from "../services/Service4Delivery/OrderPayments/ElectronicPayment";
import { Cart } from "../types/Cart";
import { Category, CategoryChild, CheckoutMenu, ItemSelectable, KioskMenu, UpSellingItem } from "../types/Menu";
import { ModalView } from "../types/Modal";
import { Order } from "../types/Order";
import { PaymentInfo } from "../types/Payment";
import { RestaurantInfo, RestaurantInfoMedia } from "../types/RestaurantInfo";
import { AppConfig, LayoutConfig, SettingsConfig } from "../types/Theme";
import { CheckoutMethod, ItemInfoTypology, PaymentMethod } from "./";

export const upSellingItemNotOrdered = 999999;

// Configurations to create a system Theme branded by Labware
const defaultSystemTheme: LayoutConfig = {
	themeName: "System Labware",
	color: {
		primary: "#EC4F54",
		secondary: "#06A475",
		background: "#FFFFFF"
	},
	font: {
		fontTitle: Font.aleo,
		fontBody: Font.notoSans
	},
	imageSet: {}
};

const preset1: LayoutConfig = {
	themeName: "Labware Experience",
	color: {
		primary: "#ED1C24",
		secondary: "#379634",
		background: "#FFFFFF"
	},
	font: {
		fontTitle: Font.prompt,
		fontBody: Font.notoSans
	},
	imageSet: {}
};

const preset2: LayoutConfig = {
	themeName: "Gelato World",
	color: {
		primary: "#FF5100",
		secondary: "#603140",
		background: "#ffffff"
	},
	font: {
		fontTitle: Font.aleo,
		fontBody: Font.notoSans
	},
	imageSet: {}
};

const preset3: LayoutConfig = {
	themeName: "Coffee Lover",
	color: {
		primary: "#B70000",
		secondary: "#C8656D",
		background: "#F3F3F3"
	},
	font: {
		fontTitle: Font.droidSerif,
		fontBody: Font.notoSans
	},
	imageSet: {}
};

const preset4: LayoutConfig = {
	themeName: "Fish Market",
	color: {
		primary: "#3149A8",
		secondary: "#2B98AD",
		background: "#F1FBFB"
	},
	font: {
		fontTitle: Font.architectsDaughter,
		fontBody: Font.notoSans
	},
	imageSet: {}
};

const preset5: LayoutConfig = {
	themeName: "Halloween",
	color: {
		primary: "#E89208",
		secondary: "#EEC070",
		background: "#030303"
	},
	font: {
		fontTitle: Font.londrinaSolid,
		fontBody: Font.notoSans
	},
	imageSet: {}
};

const defaultSettings: SettingsConfig = {
	kioskId: 0,
	menuColumn: 3,
	iconOutlineInversed: false,
	showImages: true,
	showDescription: false,
	availableCheckoutMethods: [CheckoutMethod.PLACE_NUMBER, CheckoutMethod.TAKEAWAY],
	isCashSystemEnabled: true,
	cashSystemIP: "",
	cashSystemRevenueServiceType: RevenueService.IT,
	isFiscalMode: false,
	terminalSerialNumber: "",
	printDigitalReceipt: false,
	printNonFiscalQRCode: true,
	printVariationsSplit: true,
	receiptTallonMode: false,
	skipTallonRecap: false,
	placeNumberLetters: "",
	availablePaymentMethods: [PaymentMethod.CASH],
	vatAssociations: [],
	skipPlaceNumberChoice: false,
	cardButtonsBehaviourInverted: false,
	cardHideLinkToDetails: false,
	cardHideOneClickAdd: false,
	availableLanguages: [Language.english, Language.italian, Language.german],
	isSoundEffectEnabled: false,
	hasFastSync: false
};

export const defaultConfig = {
	settings: defaultSettings,
	systemTheme: defaultSystemTheme,
	customThemes: [preset1, preset2, preset3, preset4, preset5]
};

export const defaultAppConfig: AppConfig = {
	pin: "0000",
	pinOperator: "1111",
	settings: [defaultConfig.settings],
	themes: {
		system: defaultConfig.systemTheme,
		custom: defaultConfig.customThemes[0]
	},
	versionNumber: process.env.REACT_APP_VERSION_NUMBER ?? "0.0.0",
	upSellingList: []
};

export const defaultCart: Cart = {
	items: [],
	amount: 0,
	itemsCount: 0
};

export const defaultPaymentInfo: PaymentInfo = {
	order_id: "",
	transaction_id: "",
	card_brand: "",
	card_number: "",
	meal_voucher_amount: "",
	meal_voucher_number: ""
};

export const defaultOrder: Order = {
	orderUid: "",
	checkoutMethod: CheckoutMethod.TAKEAWAY,
	placeNumber: "",
	paymentMethod: PaymentMethod.CASH,
	paymentInfo: defaultPaymentInfo,
	kioskCartId: null
};

export const defaultModalView: ModalView = {
	open: false,
	name: undefined,
	overlay: false,
	referral: undefined
};

export const defaultPaymentIntentResponse: PaymentIntentResponse = {
	kiosk_cart_id: null,
	transaction_id: undefined,
	redirect_url: undefined
};

export const defaultRestaurantInfo: RestaurantInfo = {
	country: "IT",
	currency: "EUR",
	config: defaultAppConfig,
	hide_snoozed: 0,
	media: {
		graphic: null,
		plain: null,
		standby: null,
		logo: null,
		allergen: null
	},
	media_baseurl: null
};

export const legacyDefaultRestaurantInfoMedia: RestaurantInfoMedia = {
	logo: "logo.svg",
	standby: "standby.png",
	plain: "plain.png",
	graphic: "graphic.png",
	allergen: "allergen-disclaimer.md"
};

export const defaultCategoryChild: CategoryChild = {
	categoryTitle: "",
	categoryCode: "",
	itemSelectable: [] as ItemSelectable[],
	itemSelectableAllSnoozed: false
};

export const defaultCategory: Category = {
	title: "",
	icon: "",
	children: [defaultCategoryChild]
};

export const defaultCheckoutMenu: CheckoutMenu = {
	checkout: 0,
	categories: [defaultCategory],
	selectedCategory: defaultCategory,
	selectedCategoryChildCode: defaultCategoryChild.categoryCode
};

export const defaultUpSellingItem: UpSellingItem = {
	pluId: "",
	itemSelectable: null,
	order: upSellingItemNotOrdered,
	excluded: false
};

export const defaultKioskMenu: KioskMenu = {
	checkoutMenus: [],
	categoryList: [],
	images: [],
	vatList: [],
	upSellingList: []
};

export const defaultItemSelectable: ItemSelectable = {
	itemInfo: {
		uid: "",
		tipology: ItemInfoTypology.plu,
		selectedAsDefault: null,
		orderRemark: null,
		shortText: "",
		longText: "",
		imageName: "",
		price: 0,
		snoozed: "",
		vat: "",
		vatRef: ""
	},
	itemLinkedList: [],
	categoryListLink: [],
	categoryOptListLink: []
};
