import { useEffect } from "react";
import WebFont from "webfontloader";
import { Font } from "../constants";

export const useFonts = (): void => {
	useEffect(() => {
		const availableFonts = Object.values(Font);

		WebFont.load({
			google: {
				families: availableFonts
			}
		});
	}, []);
};
