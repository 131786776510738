import { useCallback } from "react";
import useSound from "use-sound";
import { useTheme } from "../../components/Theme/ThemeWrapper";
import cart from "./../../assets/sounds/cart.mp3";
import tap from "./../../assets/sounds/tap.mp3";

export interface SoundEffectProps {
	muteSound?: boolean;
	tone?: "tap" | "cart";
}

const useSoundEffect = ({ muteSound, tone }: SoundEffectProps) => {
	const { settings } = useTheme();
	const [playCart] = useSound(cart);
	const [playTap] = useSound(tap);

	const handlePlayEvent = useCallback((): void => {
		if (muteSound || !settings.isSoundEffectEnabled) return;
		tone === "cart" ? playCart() : playTap();
	}, [muteSound, tone, settings, playCart, playTap]);

	return {
		playSound: handlePlayEvent
	};
};
export default useSoundEffect;
