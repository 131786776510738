export enum Row {
	instruction = "#",
	comment = ":"
}

export enum Command {
	cleanAndCloseReceipt = "x",
	department = "R",
	variation = "V",
	variationNote = '"',
	nonFiscal = "C9000",
	freeNote48 = "C9000/(1078002",
	freeNote40 = "C9000/(1078003",
	fiscalClosure = "C9000/(300101",
	fiscalClosureLong = "C9000/(300201",
	makeDocumentPaper = "Q0",
	makeDocumentDigital = "Q1",
	makeDocumentBoth = "Q2",
	payment = "T4",
	generateQR = "C1075"
}

export enum Block {
	separator = "/",
	value = "$", // amount in cents usually
	value2 = "&",
	value3 = "[",
	value4 = "]",
	value5 = "^",
	value6 = "_",
	value7 = "@",
	quantity = "*",
	descriptionOpen = "(", // description block should end with ')'
	descriptionClose = ")", // this is not a block... just use to get the closing tag for description block
	variationPadding = "       ",
	discount = "-",
	surcharge = "+",
	empty = "?A",
	nonFiscalInit = "(1063)",
	nonFiscalInitNoHeader = "(1063010)", // removed receipt header
	nonFiscalBody = "(106401",
	nonFiscalGraphicBody = "(111001",
	nonFiscalEnd = "(1065)"
}

export enum FontSize {
	normal = "1",
	large = "2"
}
