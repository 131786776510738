"use client";

import { CssBaseline } from "@mui/material";
import ReactDOM from "react-dom/client";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import TouchButton from "./components/Layout/Buttons/TouchButton";
import Main from "./components/Main";
import { MessageServiceProvider } from "./components/MessageHandler/MessageService";
import { ThemeWrapper } from "./components/Theme/ThemeWrapper";
import "./index.css";
import { callReloadApp } from "./services/ServiceWebview/WebviewApp";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./utils/i18n/i18n";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

function Fallback({ error, resetErrorBoundary }: FallbackProps) {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.

	return (
		<div role="alert">
			<p>Error:</p>
			<pre style={{ color: "red" }}>{error.message}</pre>

			<TouchButton variant="contained" color="info" onClick={resetErrorBoundary}>
				{"Reload"}
			</TouchButton>
		</div>
	);
}

// The theme provider is declare here and handled in Main
root.render(
	// <StrictMode>
	<ErrorBoundary
		FallbackComponent={Fallback}
		onReset={(details) => {
			// Reset the state of your app so the error doesn't happen again
			localStorage.clear();
			callReloadApp();
		}}
	>
		<ThemeWrapper>
			<MessageServiceProvider>
				<CssBaseline />
				<Main />
			</MessageServiceProvider>
		</ThemeWrapper>
	</ErrorBoundary>
	// </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Temporary remove console warning: react 19 will deprecated some feature -warning from react 18.3
if (process.env.NODE_ENV !== "production") {
	const originalWarn = console.error;
	console.error = (...args) => {
		if (args[0].includes("Support for defaultProps will be removed")) {
			return;
		}
		originalWarn(...args);
	};
}
