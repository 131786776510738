export enum PaymentMethod {
	CASH = 1,
	STRIPE = 2,
	SATISPAY = 3
}

export const electronicPaymentMethods = [PaymentMethod.STRIPE, PaymentMethod.SATISPAY];

export function getPaymentMethodName(value: number): string {
	const indexOfS = Object.values(PaymentMethod).indexOf(value as unknown as PaymentMethod);
	return Object.keys(PaymentMethod)[indexOfS].toLowerCase();
}
/**
 * Check if the given array of PaymentMethod contains some Electronic PaymentMethod
 *
 * @param methods  // array of PaymentMethods
 * @returns boolean
 */
export function hasElectronicPaymentMethods(methods: PaymentMethod[]): boolean {
	return methods.some((method) => electronicPaymentMethods.includes(method));
}
