import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ReactNode } from "react";
import TouchButton from "../Layout/Buttons/TouchButton";
import "./MessageDialog.scss";

export interface MessageOptions {
	title: string;
	description: string | ReactNode;
	okCallback: () => void;
	okLabel: string;
	cancelCallback?: () => void;
	cancelLabel?: string;
	thirdActionCallback?: () => void;
	thirdActionLabel?: string;
}

interface MessageDialogProps extends MessageOptions {
	open: boolean;
}

export const MessageDialog = ({
	title,
	description,
	okCallback,
	okLabel,
	cancelCallback,
	cancelLabel,
	thirdActionCallback,
	thirdActionLabel,
	open
}: MessageDialogProps) => {
	return (
		<Dialog className="messageContainer" open={open} maxWidth="sm">
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText component="div">{description}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<TouchButton color="primary" onClick={okCallback}>
					{okLabel}
				</TouchButton>
				{thirdActionCallback && thirdActionLabel ? (
					<TouchButton color="primary" onClick={thirdActionCallback}>
						{thirdActionLabel}
					</TouchButton>
				) : null}
				{cancelCallback && cancelLabel ? (
					<TouchButton color="primary" onClick={cancelCallback} autoFocus>
						{cancelLabel}
					</TouchButton>
				) : null}
			</DialogActions>
		</Dialog>
	);
};
