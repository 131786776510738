import { IconButton, IconButtonProps } from "@mui/material";
import { memo } from "react";
import useSoundEffect, { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";

interface TouchIconProps extends IconButtonProps, SoundEffectProps /*, LongPressEffectProps*/ {}

const TouchIcon = ({ muteSound, tone, /*hasLongPress,*/ ...rest }: TouchIconProps) => {
	const { playSound } = useSoundEffect({ muteSound, tone });
	// const { pressEvent, pressRelease } = useLongPressEffect({ hasLongPress: hasLongPress, longPressClickCallback: rest.onClick });

	return <IconButton disableRipple disableTouchRipple onClickCapture={playSound} /* onTouchStart={pressEvent} onTouchEnd={pressRelease} */ {...rest} />;
};

export default memo(TouchIcon);
