import { callAxios, callAxiosResponseInterface, Payload } from "../../../utils/httpClient/AxiosWrapper";

export interface PaymentErrorResponse {
	error_code: string;
	is_back_error: boolean;
}

export const callPayment = (payload: Payload, url: string): Promise<any> => {
	// return callAxiosErrorFakerPayment("cannot_create_intent", true);
	// return callAxiosErrorFakerPayment("reader_is_offline", true);
	// return callAxiosErrorFakerPayment("ECONNABORTED");

	return new Promise(async (resolve, reject) => {
		callAxios(
			{
				method: "post",
				url: url,
				body: JSON.stringify(payload),
				headers: JSON.stringify({ accept: "*/*" })
			},
			undefined,
			undefined,
			30000
		)
			.then((response: callAxiosResponseInterface) => {
				resolve(response.data);
			})
			.catch((err) => {
				let typedErrorResponse = {
					error_code: err.data.error_code,
					is_back_error: false
				};

				if (err.data.error_code && err.data.error_code.startsWith("error.")) {
					typedErrorResponse = {
						error_code: err.data.error_code.replace("error.", ""),
						is_back_error: true
					};
				}

				reject(typedErrorResponse);
			});
	});
};
