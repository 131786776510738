import { Service4DeliveryEndpoints } from "..";
import { LoggerChannel } from "../../../constants";
import { defaultKioskMenu } from "../../../constants/defaults";
import { KioskMenu } from "../../../types/Menu";
import { loadProductImages } from "../../../utils/cdnAssets";
import { callAxios, callAxiosResponseInterface } from "../../../utils/httpClient/AxiosWrapper";
import Logger from "../../../utils/Logger";
import { menuAdapter } from "../../../utils/menuAdapter";

interface callMenuResponse {
	status: number;
	data: {
		hash: string;
		menu: KioskMenu;
	};
}

export const MenuLoad = async (): Promise<boolean> => {
	const menuHash = localStorage.getItem("menuHash") ?? "";
	const restaurantId = localStorage.getItem("Username") ?? "";

	return new Promise(async (resolve) => {
		callAxios({
			method: "post",
			url: Service4DeliveryEndpoints.menuGet,
			body: JSON.stringify({ hash: menuHash }),
			headers: JSON.stringify({})
		})
			.then((response: callAxiosResponseInterface) => {
				const goodResponse = response as callMenuResponse;
				if (menuHash !== goodResponse.data.hash) {
					const adaptedMenu: KioskMenu = new menuAdapter(goodResponse.data.menu).adapt();
					localStorage.setItem("menu", JSON.stringify(adaptedMenu));
					localStorage.setItem("menuHash", goodResponse.data.hash);

					loadProductImages(adaptedMenu.images ?? [], restaurantId).catch((reason: any) => {
						Logger.log(reason, LoggerChannel.api);
					});
				}
				resolve(true);
			})
			.catch((err) => {
				Logger.error(["Retrieve menu error: ", err], LoggerChannel.api);
				resolve(false);
			});
	});
};

export const GetMenuFromStorage = (): KioskMenu => {
	return JSON.parse(localStorage.getItem("menu") ?? JSON.stringify(defaultKioskMenu));
};
