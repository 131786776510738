export enum CheckoutFlowStep {
	orderMaking = "00_orderMaking",
	orderReview = "10_orderReview",
	checkoutMethod = "20_checkoutMethod",
	placeNumber = "21_placeNumber",
	paymentMethod = "30_paymentMethod",
	paymentInfo = "31_paymentInfo",
	// receiptMethod = "40_receiptMethod",
	orderConfirmation = "90_orderConfirmation"
}
