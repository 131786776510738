const kioskBasePath = (process.env.REACT_APP_4DELIVERY_BASE_URL ?? "") + "/api/v1/kiosk";

/**
 * 4Delivery Service - Kiosk endpoint's
 */
export const Service4DeliveryEndpoints = {
	authLogin: kioskBasePath + "/login-test",
	configGet: kioskBasePath + "/restaurant-info-get",
	configSet: kioskBasePath + "/restaurant-info-set",
	menuGet: kioskBasePath + "/menu",
	orderNoPayment: kioskBasePath + "/order/without-payment", // orderUid as idempotence key
	orderDocument: kioskBasePath + "/order/document",
	satispayRequest: kioskBasePath + "/satispay/request", // orderUid as idempotence key
	satispayUpdate: kioskBasePath + "/satispay/update-status",
	satispayCancel: kioskBasePath + "/satispay/cancel",
	satispayTest: kioskBasePath + "/satispay/test",
	stripeRequest: kioskBasePath + "/payment/request", // orderUid as idempotence key
	stripeUpdate: kioskBasePath + "/payment/update-status",
	stripeCancel: kioskBasePath + "/payment/cancel",
	stripeTest: kioskBasePath + "/payment/test"
};
