import { Close, HighlightOffRounded } from "@mui/icons-material";
import { Alert, CircularProgress, Collapse, Divider, Stack, TextField, ThemeProvider } from "@mui/material";
import { t } from "i18next";
import { ChangeEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callLogin, callLoginResponse } from "../../services/Service4Delivery/Auth/Login";
import { useRestaurantInfo } from "../../services/Service4Delivery/Config/useRestaurantInfo";
import { useMenu } from "../../services/Service4Delivery/Menu/useMenu";
import { callCloseApp } from "../../services/ServiceWebview/WebviewApp";
import { callAxiosResponseInterface } from "../../utils/httpClient/AxiosWrapper";
import TouchButton from "../Layout/Buttons/TouchButton";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";

const Login = () => {
	const message = useMessage();
	const { theme, setContextRestaurantId } = useTheme();
	const { menuError } = useMenu();
	const navigate = useNavigate();
	const { downloadConfigAndMenu } = useRestaurantInfo();

	const [username, setUsername] = useState<string>(localStorage.getItem("Username") ?? "");
	const [password, setPassword] = useState<string>(localStorage.getItem("Password") ?? "");
	const [formError, setFormError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const handleSaveCredentials = async () => {
		localStorage.setItem("Username", username);
		localStorage.setItem("Password", password);

		setLoading(true);
		const loginResponse: callLoginResponse | callAxiosResponseInterface = await callLogin();

		if (loginResponse.status === 200 && loginResponse.data.success) {
			if (setContextRestaurantId) setContextRestaurantId(username);

			await downloadConfigAndMenu();

			if (menuError) {
				alert("Error retrieving menu");
			}

			navigate("/");
		} else {
			setFormError(loginResponse.data.error_code);
		}
		setLoading(false);
	};

	const handleCloseApp = useCallback(() => {
		message({
			title: t("system.admin.access.closeApp"),
			description: t("system.admin.access.closeAppConfirm"),
			okCallback: () => {
				callCloseApp();
			},
			okLabel: t("common.yes"),
			cancelCallback: () => {},
			cancelLabel: t("common.cancel") ?? ""
		});
	}, [message]);

	const handleNavigateHome = useCallback(() => navigate("/"), [navigate]);
	const handleCloseError = useCallback(() => setFormError(""), []);
	const handleChangeUsername = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUsername(e.target.value), []);
	const handleChangePassword = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setPassword(e.target.value), []);

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<Stack
				alignItems="center"
				justifyContent="center"
				sx={{
					backgroundColor: theme.systemTheme.palette.grey.A400,
					height: "100vh"
				}}
			>
				<Stack
					alignItems="center"
					component="form"
					borderRadius="1rem"
					boxShadow="10"
					padding="1rem"
					sx={{
						backgroundColor: theme.systemTheme.palette.background.default,
						width: "40vw",
						"& .MuiTextField-root,.MuiButton-root": {
							margin: 2
						}
					}}
					autoComplete="off"
				>
					<TouchButton
						color="info"
						onClick={handleNavigateHome}
						startIcon={<HighlightOffRounded />}
						size="large"
						sx={{
							width: "100%",
							justifyContent: "flex-end",
							"& svg": {
								fontSize: "3rem !important"
							}
						}}
					/>
					<TextField
						error={formError !== ""}
						id="input-username"
						label={t("system.admin.access.restaurantId")}
						defaultValue={username}
						onChange={handleChangeUsername}
					/>
					<TextField
						error={formError !== ""}
						id="input-password"
						label={t("common.password")}
						type="password"
						defaultValue={password}
						onChange={handleChangePassword}
					/>
					<TouchButton
						color="success"
						variant="contained"
						onClick={handleSaveCredentials}
						sx={{ minWidth: "50%" }}
						endIcon={loading ? <CircularProgress sx={{ marginLeft: "2rem" }} /> : null}
						disabled={loading}
					>
						{t("common.login")}
					</TouchButton>
					<Collapse in={formError !== ""}>
						<Alert severity="error" onClose={handleCloseError}>
							{t(`system.error.${formError}`)}
						</Alert>
					</Collapse>
					<Divider sx={{ my: 5 }}></Divider>
					<TouchButton variant="contained" color="primary" onClick={handleCloseApp} endIcon={<Close />} sx={{ mx: 2 }}>
						{t("system.admin.access.closeApp")}
					</TouchButton>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default Login;
