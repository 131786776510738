export enum Language {
	english = "en",
	italian = "it",
	german = "de",
	french = "fr",
	spanish = "es",
	dutch = "nl"
}

export function getLanguageName(value: string): string {
	const indexOfS = Object.values(Language).indexOf(value as unknown as Language);
	return Object.keys(Language)[indexOfS].toLowerCase();
}
