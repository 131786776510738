import { Box, Paper, Typography, alpha } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player/file";
import { useGetRestaurantMedia } from "../../utils/cdnAssets";
import { useTheme } from "../Theme/ThemeWrapper";
import "./Home.scss";

const HomeBackground = () => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { url: bgUrl, isFallback: isBgFallback, isVideo: isBgVideo } = useGetRestaurantMedia("standby");
	const palette = useMemo(() => theme.customTheme.palette, [theme]);

	const [videoHasError, setVideoHasError] = useState<boolean>(false);
	const isVideoPlayable: boolean = useMemo(() => isBgVideo && !videoHasError, [isBgVideo, videoHasError]);

	const handleVideoPlayerError = useCallback(() => setVideoHasError(true), []);

	return (
		<>
			<Box
				className={`bgLayer ${isBgFallback ? "animated" : ""}`}
				sx={{
					background: `${isBgFallback ? "" : "url(" + bgUrl + "), "}conic-gradient(from 90deg, ${palette.primary.light}, ${palette.primary.dark}, ${
						palette.primary.light
					})`
				}}
			/>
			{isVideoPlayable ? (
				<Box className={"bgVideoWrapper"}>
					<ReactPlayer
						url={bgUrl}
						muted={true}
						loop={true}
						playing={true}
						volume={0}
						width={"100%"}
						height={"100%"}
						onError={handleVideoPlayerError}
						fallback={<Box width={"100%"} height={"100%"} position={"absolute"} top={0} />}
						config={{
							attributes: { poster: undefined, preload: "none" }
						}}
					/>
				</Box>
			) : null}

			{isBgFallback || (isBgVideo && !isVideoPlayable) ? (
				<Paper
					className="buttonGraphic orderHere"
					elevation={0}
					sx={{
						backgroundColor: alpha(palette.background.paper, 0.95),
						color: palette.primary.main
					}}
				>
					<Typography color="info" variant="h3">
						{t("checkout.orderHere")}
					</Typography>
				</Paper>
			) : null}
		</>
	);
};

export default memo(HomeBackground);
